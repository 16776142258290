h1 {
  display: inline-block;
  font-size: larger;
  margin: 0 1em 0.5em 0;
}

h2 {
  font-size: large;
}

h3 {
  font-size: medium;
  margin-bottom: 0;
}

main {
  padding: 1em;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: visible;
}

input {
  max-width: 3em;
}

.controls-toggle {
  display: block;
}

.hide {
  display: none;
}

footer {
  margin-top: 1em;
  text-align: center;
}
