.picker button {
  height: 1.6em;
  width: 2em;
  border: 1px solid #aaa;
}
.picker {
  position: relative;
}
.picker-panel {
  position: absolute;
  z-index: 1;
}
.picker-panel--left {
  right: 0;
}
.picker-panel--right {
  left: 0;
}
.picker-panel--center {
  left: calc(-100px + 50%);
}

.picker-panel--bottom {
  top: 100%;
}
.picker-panel--top {
  bottom: 100%;
}
